import React from "react";
import {
  ContentBannerCinnecta,
  ContainerBannerCinnecta,
} from "./styledBannerCinnecta";
import { PlayerVideo } from "../playerVideo";
import { Button } from "../../../components/Button/Button";
import { Link } from "gatsby";

export const BannerCinnecta = ({ data, videoUrl }) => {
  return (
    <ContainerBannerCinnecta id="sobre_nos">
      <ContentBannerCinnecta>
        <h1>{data?.titleBanner}</h1>
        <p>{data?.subTitleBanner}</p>
        {data?.buttonSlug ? (
          <Link to={`/${data?.buttonSlug}`}>
            <Button type="button" label={data?.buttonText} />
          </Link>
        ) : (
          <a href={data?.buttonLink} target="blank">
            <Button type="button" label={data?.buttonText} />
          </a>
        )}
      </ContentBannerCinnecta>
      <PlayerVideo videoUrl={videoUrl} />
    </ContainerBannerCinnecta>
  );
};
