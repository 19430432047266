import { gql } from "graphql-request";

export const getHomepage = gql`
  query homepage {
    homepage(where: { slug: "home" }) {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
      banner {
        titleBanner
        subTitleBanner
        imageBanner {
          url
          fileName
        }
        buttonText
        buttonSlug
        buttonLink
      }
      infoCard {
        titleInfo
        bodyInfo
        imageInfo {
          url
        }
      }
      depositionsCard {
        nameAgent
        nameCompany
        officeAgent
        bodyDeposition
        logoCompany {
          fileName
          url
        }
        imageAgent {
          url
          fileName
        }
      }
      leadingCompany {
        titleLeadingCompany
        imageLeadingCompany {
          url
          id
          fileName
          textAlt
        }
      }
      stampsTitle
      imageStamp {
        url
      }
      formTitle
      stapmsCinnecta {
        id
        descriptionStamp
        positionStamp
      }
      productsTitle
      productsCinnecta {
        id
        nameProduct
        descriptionProduct
        bodyProduct {
          html
        }
        labelButton
        redirectButton
        reverseCard
        imageProduct {
          url
        }
      }
    }
  }
`;
