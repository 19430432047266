import React from "react";
import { ContainerVideo, ContentContainer } from "./styledPlayerVideo";

export function PlayerVideo({videoUrl}) {
  return (
    <ContainerVideo id="player_video">
      <ContentContainer>
        <iframe
          frameBorder="0"
          allowFullScreen
          title="YouTube video player"
          src={videoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </ContentContainer>
    </ContainerVideo>
  );
}
