import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { useAbout } from "../../hooks/methods/about";
import { useHome } from "../../hooks/methods/home";
import Layout from "../../templates/MainTemplate";
import { BannerCinnecta } from "../../view/cinnecta/banner";
import { CultureCinnecta } from "../../view/cinnecta/culture";
import { Customers } from "../../view/cinnecta/customers";
import { NewsAbout } from "../../view/cinnecta/newsAbout";
import { Timeline } from "../../view/cinnecta/timeline";
import { ValuesCinnecta } from "../../view/cinnecta/valuesCinnecta";
import { StampsView } from "../../view/Home/stamps"

export default function Solutions() {
  const { data: homeData, isSuccess: homeSuccess } = useHome();
  const { data: aboutData, isSuccess: aboutSuccess } = useAbout();

  const { imageStamp, stapmsCinnecta } = homeSuccess && homeData?.homepage;
  const dataStamps = [imageStamp, stapmsCinnecta];

  const {
    newsAbout,
    newsAboutTitle,
    bannerAbout,
    cardCinnecta,
    imageCulture,
    valuesCinnecta,
    timelineContent,
    depositionCompanies,
    videoUrl,
    stampsTitle,
  } = aboutSuccess && aboutData?.aboutPage;
  return (
    <>
      <Layout>
        <BannerCinnecta data={bannerAbout} cardCinnecta={cardCinnecta} videoUrl={videoUrl} />
        <Timeline data={timelineContent} />
        <Customers data={depositionCompanies} />
        <ValuesCinnecta data={valuesCinnecta} />
        <CultureCinnecta data={imageCulture} />
        <NewsAbout data={newsAbout} title={newsAboutTitle} />
        <StampsView data={homeSuccess && dataStamps} title={stampsTitle} />
      </Layout>
    </>
  );
}

export const Head = ({
  data: {
    cmsAboutPage: 
      {seo: { title: seoTitle, description: seoDescription, keywords, image: {url} }},
  },
}) => {
  const keywordsList = keywords.toString()
  return (
    <Seo title={seoTitle} description={seoDescription} keywords={keywordsList} url={url} />
  );
};

export const query = graphql`
  query PageQuery {
    cmsAboutPage {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
    }
  }
`;
